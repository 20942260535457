import { styled } from '@linaria/react';

import { COLOR, SHADOW, TEXT, TRANSITION, MEDIA } from 'theme/vars';

export const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 272px;
  height: 44px;

  ${TEXT.BUTTON_CAPS};
  background: var(${COLOR.C_PRIMARY});
  color: var(${COLOR.C_ON_PRIMARY});
  border-radius: 8px;
  cursor: pointer;
  transition: var(${TRANSITION.DEFAULT});

  &:hover,
  &:active,
  &:focus {
    box-shadow: var(${SHADOW.DEFAULT});
  }

  &:disabled {
    background: var(${COLOR.B_SECONDARY_200});
    color: var(${COLOR.N_SECONDARY_300});
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (min-width: ${MEDIA.TABLET}) {
    width: 335px;
    height: 56px;
  }

  &[data-small] {
    @media (min-width: ${MEDIA.TABLET}) {
      width: 272px;
      height: 44px;
    }
  }
`;
