import { styled } from '@linaria/react';

export const Wrapper = styled.div`
  margin: 0 auto;
  height: 100vh;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div``;

export const Title = styled.h1`
  margin-bottom: 40px;
`;

export const Description = styled.p`
  margin-bottom: 40px;
`;
