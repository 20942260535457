import * as React from 'react';

import { Wrapper } from './styled';

type ButtonProps = {
  className?: string;
  onClick?: React.MouseEventHandler;
  children: React.ReactNode;
  disabled?: boolean;
  small?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  className,
  onClick,
  children,
  disabled,
  small,
}) => (
  <Wrapper
    className={className}
    onClick={onClick}
    disabled={disabled}
    data-small={small || undefined}
    type='button'
  >
    {children}
  </Wrapper>
);

export default Button;
